import React from "react"
import Footer from "../../../../components/Footer/Footer";
import SupportLeftNav from "../../../../components/TwoColumnLeftNav/SupportLeftNav";
import SoftwareDownloadButton from "../../../../components/SoftwareDownload/SoftwareDownloadButton";
import {LocaleContext} from "../../../../components/layout";

const Kioku74j = () => {
    const {locale} = React.useContext(LocaleContext)
    const Kioku74jJa = () => {
        return (
                    <div className="content-container">
                        <h2 className={'large bold'}>記憶計 SK-L750シリーズ用　ソフトウェアダウンロード</h2>
                        
                        <p className="important-text">2023年5月30日現在、グラフ印刷時の不具合が確認されています。<br/>
                           詳細は<a href="/20230530/" target={"_blank"} rel="noreferrer">こちら</a>をご覧ください</p>
                                        
                        <div className="software-content">
                            <div className="terms-of-service-container">
                                <div className="content-pre">
                                    <p className="equal-line">＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝</p>
                                    <p>このドキュメントは本製品に関する重要な情報について書かれています。<br/>
                                        ご使用の前に、必ずご一読くださるようお願いいたします。</p>
                                    <p className="equal-line">＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝</p>
                                </div>
                                <div className="content-title">
                                    <p>＜利用規約＞</p>
                                </div>
                                <div className="content-disclaimer">
                                    <div className="content-disclaimer-title">
                                        <p> ■免責事項</p>
                                    </div>
                                    <div className="content-disclaimer-content">
                                        <p>
                                            株式会社佐藤計量器製作所は本製品に関して動作確認はおこなっておりますが、全ての状況下において動作を保証しているわけではありません。<br/>
                                            本製品により生じた損害は、直接的および間接的損害を問わず、弊社に一切の賠償責任はないものとします。<br/>
                                            操作上のミス、注意を無視した操作、ご使用になられているＰＣに起因する不具合に対して、弊社は責任を負いかねますのでご了承ください。<br/><br/>
                                            ※こちらで提供いたしております各種ソフトウェアは、日本国内で販売されている各製品と日本語版OSの組み合わせのみ、動作確認をおこなっております。<br/>
                                            海外版OSとの組み合わせにて発生したトラブルに関しましては、一切保証およびサポートいたしかねますのでご了承ください。
                                        </p>
                                    </div>
                                </div>
                                <div className="content-copyright">
                                    <div className="content-copyright-title">
                                        <p> ■著作権</p>
                                    </div>
                                    <div className="content-copyright-content">
                                        <ol>
                                            <li>
                                                「SKSATO記憶計 for Windows」のプログラムおよび関連ドキュメントに関する著作権は株式会社佐藤計量器製作所に帰属します。
                                            </li><br/>
                                            <li>
                                                「SKSATO記憶計 for Windows」は以下条件をもとに、無償でご利用いただけます。
                                                <ul>
                                                    <li>
                                                        <p>逆コンパイル・ファイル解析、改変等を禁じます。</p>
                                                    </li>
                                                    <li>
                                                        <p>本プログラムの使用による損害は、弊社は一切の責任を負いません。</p>
                                                    </li>
                                                    <li>
                                                        <p>再配布は自由としますが、企業内・企業間、その他関係においても営利目的にしないこと。<br/>
                                                            再配布したプログラムの使用による損害に対しても弊社は一切の責任を負いません。</p>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ol>
                                        <p>本利用規約に同意いただけた方にのみ、以下ソフトウェアをご利用いただけます。</p>
                                    </div>
                                </div>
                                <div className="content-feature">
                                    <div className="content-feature-title">
                                        <p> ■ソフトウェアの特徴</p>
                                    </div>
                                    <div className="content-feature-content">
                                        <ol>
                                            <li>
                                                <p>「SKSATO記憶計 for Windows」は、データロガー記憶計SK-L750シリーズ各機器の設定、データ解析をおこなうものです。</p>
                                            </li>
                                            <li>
                                                <p>測定、記憶したデータは「SKSATO記憶計 for Windows」を用いてパソコンに回収し、データの解析（グラフ表示、印刷等）がおこなえます。</p>
                                                <p>また、データをCSV形式のファイルとして保存した場合は、市販のCSV形式対応の表計算ソフトでデータ解析が可能です。</p>
                                            </li>
                                            <li>
                                                <p>リアルタイム監視機能では、複数台の機器の計測値を定期的に取得してソフトウェアで確認することができます。</p>
                                                <p>暑さ指数の監視もおこなえ、取得した警報情報をメールで知らせることもできます。</p>
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="way-to-install">
                                <div className="install-title">
                                    <p className="important-text">(インストール前に必ずお読みください。)</p>
                                </div>
                            </div>
                      
                            <div className="way-to-install">
                                <div className="install-title">
                                    <p className="bold">【インストール方法】</p>
                                </div>
                                <div className="install-content">
                                    <p>「<a href="https://d3hdfc67hsdxgb.cloudfront.net/corporate/pdfs/記憶計ソフトウェアインストールマニュアル_7x.pdf" target={"_blank"} rel="noreferrer">記憶計ソフトウェアインストールマニュアル.pdf</a>」に従ってインストールをおこなってください。</p>
                                    <p>ダウンロードしたファイルを実行するとソフトウェアのインストールが始まります。</p>
                                </div>
                            </div>
                            <div className="download">
                                <div className="download-title">
                                    <p className="bold">【ダウンロード】</p>
                                </div>
                                <div className="install-content">
                                    <p>※ご使用されるパソコンのOSバージョンおよび、bit数をご確認の上、対応するソフトウェアをダウンロードしてください。</p>
                                    <p>※本ソフトウェアはSK-L750シリーズ用です。SK-L200／L200Ⅱシリーズではご使用できません。</p>
                                    <p className="important-text">※SK-L751またはSK-L754をWindows8.1でご使用になる場合は、<a href="/support/software-download/kioku73j" target={"_blank"} rel="noreferrer">こちらからバージョン7.3J</a>をダウンロードしてください。</p>
                                </div>
                                <div className="download-content">
                                    <table>
                                        <tbody>
                                        <tr>
                                            <th>
                                                <p className="download-table-title">ソフトウェア名</p>
                                            </th>
                                            <td>
                                                <p>SKSATO記憶計 for Windows Ver.7.4J　　（2022年1月11日掲載）</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                <p className="download-table-title">対象機器</p>
                                            </th>
                                            <td>
                                                <p>SK-L750シリーズ（SK-L751・SK-L753・SK-L754）</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                <p className="download-table-title">対象OS</p>
                                            </th>
                                            <td>
                                                <p>
                                                    Windows11<br/>
                                                    Windows10 （64bit版/32bit版）
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                <p className="download-table-title">ダウンロード</p>
                                            </th>
                                            <td>
                                                <SoftwareDownloadButton
                                                    yourKey64bit="corporate/software/kioku74J_64.zip"
                                                    tmpKey64bit="software/kioku74J_64.zip"
                                                    yourKey32bit="corporate/software/kioku74J_32.zip"
                                                    tmpKey32bit="software/kioku74J_32.zip"
                                                />
                                                <p>※ご使用のウェブブラウザーによっては、ダウンロードが正しくおこなわれない場合があります。<br/>
                                                　その場合は下記をご参照の上、ダウンロードをおこなってください。<br/>
                                                　　Microsoft Edge：<a href="https://d3hdfc67hsdxgb.cloudfront.net/corporate/pdfs/Edgeご使用時の補足.pdf" target={"_blank"} rel="noreferrer">「Edgeご使用時の補足.pdf」</a><br/>
                                                　　Google Chrome ：<a href="https://d3hdfc67hsdxgb.cloudfront.net/corporate/pdfs/Chromeご使用時の補足.pdf" target={"_blank"} rel="noreferrer">「Chromeご使用時の補足.pdf」</a>
                                                </p>                        
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                <p className="download-table-title">バージョンアップ内容</p>
                                            </th>
                                            <td>
                                                <p>新機種SK-L753へ対応しました。</p>
                                                <p>対象OSがWindows10のみとなりました。</p>
                                                <p>※新機種SK-L753はWindows10以前のOSには未対応です。</p>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="version-up-history">
                                <div className="version-up-title">
                                    <p>【バージョンアップ履歴】</p>
                                </div>
                                <div className="version-up-contents">
                                    <ol>
                                        <li>
                                            <p>Ver.7.3J（2021年2月17日掲載）</p>
                                            <p>メール送信設定機能をSTARTTLS方式へ対応</p>
                                        </li>  
                                        <li>
                                            <p>Ver.7.2J（2020年11月20日掲載）</p>
                                            <p>英語版ソフトウェアとの統合化</p>
                                        </li>
                                        <li>
                                            <p>Ver.7.1J（2020年8月17日掲載）</p>
                                            <p>以下の不具合を修正しました。</p>
                                            <p>　ソフトウェアに、11台以上の機器登録ができない問題を修正</p>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
        )
    }
    const Kioku74jEn = () => {
    return (
        <div className="content-container">
            <h2 className={'large bold'}>Software for SK-L750 Series Dataloggers</h2>

            <p className="important-text">As of May 30, 2023, an unsolved issue regarding graph printing is reported.<br/>
            Refer <a href="https://www.sksato.co.jp/en/20230111/" target={"_blank"} rel="noreferrer">here</a> for details.</p>

            <div className="software-content">
                <div className="terms-of-service-container">
                    <div className="content-pre">
                        <p className="equal-line">＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝</p>
                        <p>This document describes important information about this product.<br/>
                        Read it thoroughly before use.</p>
                        <p className="equal-line">＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝</p>
                    </div>
                    <div className="content-disclaimer">
                        <div className="content-disclaimer-title">
                            <p className="bold"> Software Use Agreements</p>
                        </div>
                        <div className="content-disclaimer-content">
                            <p>This software shall be used only by users who have agreed on the following terms:</p>
                            <p className="bold">Exemption from liability</p>
                            <p>We, Sato Keiryoki Mfg. Co., Ltd., check for general operation performance of the program, however we do not guarantee for those under all possible situations.<br/>
                            We do not take any responsibilities on any damages caused directly or indirectly by this program.<br/>
                            </p>
                            <p>NB. The operation of our software has been checked by a combination of the Japanese edition OS and the device sold in Japan. We shall not guarantee nor support the problems that occurred during the usage of device and/or OS of overseas model (edition).</p>
                        </div>
                    </div>
                    <div className="content-copyright">
                        <div className="content-copyright-title">
                            <p className="bold">Copyright</p>
                        </div>
                        <div className="content-copyright-content">
                            <ol>
                                <li>
                                    All rights related to the program itself and documentations of the software are reserved by Sato Keiryoki Mfg. Co., Ltd.
                                </li><br/>
                                <li>The software can be used at free of charge under following limitations:
                                    <ul>
                                        <li>
                                            <p>Any decompilation, file analysis or alteration is prohibited.</p>
                                        </li>
                                        <li>
                                            <p>We shall not be responsible for any damage resulting from the use of this program.</p>
                                        </li>
                                        <li>
                                            <p>The responsibilities related to redistribution of the program are entirely onto users. In case of redistribution, the program shall not be sold commercially no matter how it is used.<br/>
                                            We shall not be responsible for any damage resulting from the use of the redistributed program.</p>
                                        </li>
                                    </ul>
                                </li>
                            </ol>
                        </div>
                    </div>
                    <div className="content-feature">
                        <div className="content-feature-title">
                            <p className="bold">Features of the Software</p>
                        </div>
                        <div className="content-feature-content">
                            <ol>
                                <li>
                                    <p>"DATALOGGER for Windows" is used to set the various settings in the SK-L750 Series Dataloggers and analyze the acquired data.</p>
                                </li>
                                <li>
                                    <p>Measured data are transferred to PC through this software and then displayed as graphs, printed, or analyzed.</p>
                                    <p>Exporting the data in CSV format enables other spreadsheet software to edit it.</p>
                                </li>
                                <li>
                                    <p>Also, it offers real-time monitoring function to acquire data regularly from multiple devices and to display them.</p>
                                    <p>If you set alert for a certain value of WBGT then you can receive alert via email.</p>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
                    <div className="way-to-install">
                        <div className="install-title">
                            <p className="bold">How to Install</p>
                        </div>
                        <div className="install-content">
                            <p>Download and install the software following the <a href="https://d3hdfc67hsdxgb.cloudfront.net/corporate/pdfs/Manual_Software_for_SK-L750.pdf" target={"_blank"} rel="noreferrer"><span class="underline">Software manual for SK-L750 Series</span></a>.</p>
                            <p>When the installation is complete, connect the device and the PC with a USB cable attached in the kit and turn on the device.</p>
                        </div>
                    </div>
                    <div className="download">
                        <div className="download-title">
                            <p className="bold">Software Download</p>
                        </div>
                        <div className="download-pre">
                            <p>Be sure to check the OS and the bit version of your computer before installation.</p>
                            <p>This software is solely for SK-L750 Series, and not usable with SK-L200/L200II Series.</p>
                            <p className="important-text">To use SK-L751 or SK-L754 on Windows8.1, download Ver. 7.3E from <a href="https://www.sksato.co.jp/en/support/software-download/kioku73j" target={"_blank"} rel="noreferrer">this page</a>. Note that SK-L753 does not support OS previous to Windows10.</p>
                        </div>
                        <div className="download-content">
                            <table>
                                <tbody>
                                <tr>
                                    <th>
                                        <p className="download-table-title">Program Name</p>
                                    </th>
                                    <td>
                                        <p>DATALOGGER for Windows Ver.7.4E (uploaded on January 11, 2022)</p>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <p className="download-table-title">Model</p>
                                    </th>
                                    <td>
                                        <p>SK-L751, SK-L753, SK-L754</p>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <p className="download-table-title">OS</p>
                                    </th>
                                    <td>
                                        <p>Windows10 (64/32bit)</p>
                                        <p>Operation verification is done in Professional 64bit and 32bit.</p>
                                        <p>Windows11</p>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <p className="download-table-title">Download</p>
                                    </th>
                                    <td>
                                        <SoftwareDownloadButton
                                            yourKey64bit="corporate/software/kioku74E_64.zip"
                                            tmpKey64bit="software/kioku74E_64.zip"
                                            yourKey32bit="corporate/software/kioku74J_32.zip"
                                            tmpKey32bit="software/kioku74J_32.zip"
                                        />
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <>
            <article className="company-info two-column-left-nav">
                <SupportLeftNav/>
                <section className="main-content">
                    {(locale === 'ja')?
                        <Kioku74jJa/>:
                        <Kioku74jEn/>
                    }
                </section>
            </article>
            <Footer/>
        </>
    )
}
export default Kioku74j
